// Colors
$color__brand-green: #8dd0bc;
$color__brand-dark-green: #00a3db;
$color__brand-black: #404040;
$color__brand-purple: #562a7a;
$color__brand-red: #ed2227;
$color__brand-darken-red: #d92229;
$color__brand-orange: #ec8b2e;
$color__brand-grey: #f6f6f6;
$color__brand-blue: #0965a6;
// Fonts
$font-family__1: 'Gotham-Bold', sans-serif;
$font-family__2: 'Gotham-Book', sans-serif;
$font-family__3: 'GothamMedium', sans-serif;
$font-family__4: 'GothamBlack', sans-serif;

$font-size__1: 34px;
$font-size__2: 16px;
$font-size__3: 24px;
$font-size__4: 18px;
$font-size__5: 14px;
$font-size__6: 56px;

// Breakpoints
$responsive-breakpoint: 736px;

