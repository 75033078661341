.dfd_tabs_block {
  .vc_tta-container {
    margin-bottom: 0 !important;
  }
  .tabs-1.dfd_tta_tabs.collapse .vc_tta-tabs-container {
    text-align: center !important;
    .vc_tta-tabs-list {
      li {
        a {
          border: none !important;
          font-family: $font-family__1;
        }
        &:nth-of-type(1) {
          a {
            color: $color__brand-purple !important;
          }
          &.vc_active {
            a {
              border: 1px solid $color__brand-purple !important;
              color: $color__brand-purple;
              background-color: transparent !important;
            }
          }
        }
        &:nth-of-type(2) {
          a {
            color: $color__brand-red !important;
          }
          &.vc_active {
            a {
              border: 1px solid $color__brand-red !important;
              color: $color__brand-red;
              background-color: transparent !important;
            }
          }
        }
        &:nth-of-type(3) {
          a {
            color: $color__brand-orange !important;
          }
          &.vc_active {
            a {
              border: 1px solid $color__brand-orange !important;
              color: $color__brand-orange;
              background-color: transparent !important;
            }
          }
        }
        &:nth-of-type(4) {
          a {
            color: $color__brand-blue !important;
          }
          &.vc_active {
            a {
              border: 1px solid $color__brand-blue !important;
              color: $color__brand-blue;
              background-color: transparent !important;
            }
          }
        }
      }
    }
  }
  .tabs-2.dfd_tta_tabs.collapse .vc_tta-tabs-container {
    text-align: center !important;
    .vc_tta-tabs-list {
      li {
        a {
          border: none !important;
          font-family: $font-family__1;
          color: #fff;
        }
        &.vc_active {
          a {
            background-color: transparent !important;
            border: 1px solid #fff !important;
          }
        }
        //&:nth-of-type(1) {
        //  a {
        //    color: $color__brand-purple !important;
        //  }
        //  &.vc_active {
        //    a {
        //      border: 1px solid $color__brand-purple !important;
        //      color: $color__brand-purple;
        //      background-color: transparent !important;
        //    }
        //  }
        //}
        //&:nth-of-type(2) {
        //  a {
        //    color: $color__brand-red !important;
        //  }
        //  &.vc_active {
        //    a {
        //      border: 1px solid $color__brand-red !important;
        //      color: $color__brand-red;
        //      background-color: transparent !important;
        //    }
        //  }
        //}
        //&:nth-of-type(3) {
        //  a {
        //    color: $color__brand-orange !important;
        //  }
        //  &.vc_active {
        //    a {
        //      border: 1px solid $color__brand-orange !important;
        //      color: $color__brand-orange;
        //      background-color: transparent !important;
        //    }
        //  }
        //}
        //&:nth-of-type(4) {
        //  a {
        //    color: $color__brand-blue !important;
        //  }
        //  &.vc_active {
        //    a {
        //      border: 1px solid $color__brand-blue !important;
        //      color: $color__brand-blue;
        //      background-color: transparent !important;
        //    }
        //  }
        //}
      }
    }
  }
}