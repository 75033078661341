@font-face {
  @include font-face('Gotham-Bold');
}

@font-face {
  @include font-face('Gotham-Book');
}

@font-face {
  @include font-face('GothamMedium');
}

@font-face {
  @include font-face('GothamBlack');
}