.btn-purple {
  @extend %btn-purple;
}
.btn-red {
  @extend %btn-red;
}
.btn-orange {
  @extend %btn-orange;
}


.btn-purple-no-icon {
  @extend %btn-purple;
  &:before {
    display: none;
  }
}
.btn-red-no-icon {
  @extend %btn-red;
  &:before {
    display: none;
  }
}



.tp-caption.rev-btn.btn-white-filled {
  @extend %btn-white-filled;
}

.btn-filled {
  color: #fff;
  padding: 15px 20px;
  text-transform: uppercase;
  font-family: $font-family__2;
  display: inline-block;
  margin: 20px 0;
}

.btn-filled--red {
  background-color: $color__brand-red;
  &:hover {
    background-color: lighten($color__brand-red, 5%);
    color: #fff;
  }
}

.btn-filled--purple {
  background-color: $color__brand-purple;
  &:hover {
    background-color: lighten($color__brand-purple, 5%);
    color: #fff;
  }
}