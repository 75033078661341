h1.tp-caption {
  font-style: normal !important;
  font-family: $font-family__1 !important;
  text-transform: uppercase;
  font-size: $font-size__6 !important;
  letter-spacing: 4px !important;
  font-weight: normal !important;
  line-height: $font-size__6 * 1.3 !important;

  @media only screen and (max-width : 1024px) {
    font-size: $font-size__1 !important;
    line-height: $font-size__1 * 1.3 !important;
  }

  @media only screen and (max-width : 568px) {
    font-size: $font-size__3 !important;
    line-height: $font-size__3 * 1.3 !important;
  }
}

.tp-caption.rev-btn {
  @extend %btn-white;
}

.tp-revslider-mainul {
  li {
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgba(#000,.5);
    }
  }
}