#menu-corporate_menu {
  li {
    a {
      text-transform: uppercase;
      font-weight: normal;
      font-family: $font-family__3;
      transition: color .3s ease-out;
      color: $color__brand-black !important;
    }
    &:after {
      display: none !important;
    }
    &:hover {
      a {
        color: rgba($color__brand-black,.7) !important;
      }
    }
  }
}

.sticky-header-enabled.small {
  #menu-corporate_menu {
    li {
      a {
        color: #fff !important;
      }
      &:hover {
        a {
          color: rgba(#fff,.7) !important;
        }
      }
    }
  }
}

.home, .page-id-18296, .page-id-18116 {
  #menu-corporate_menu {
    li {
      a {
        color: #fff !important;
      }
      &:hover {
        a {
          color: rgba($color__brand-black,.7) !important;
        }
      }
    }
  }
}