.dfd-blog-loop .dfd-blog-wrap .post .cover .dfd-news-categories .byline.category a {
  background: $color__brand-purple;
  font-family: $font-family__2;
}

.dfd-blog-title {
  font-family: $font-family__1;
  letter-spacing: 0;
  font-size: $font-size__3;
}

.dfd-blog-posts-module.dfd-blog-loop.standard .post .cover .entry-media .dfd-blog-heading-wrap {
  padding: 20px;
}

.dfd-blog {
  .entry-thumb {
    &:after {
      content: '';
      background: rgba(0,0,0,.3);
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      position: absolute;
    }
  }
}

.sort-panel {
  text-align: left;
  .filter {
    li {
      line-height: 20px;
      &.active {
        a {
          border-color: #000;
        }
      }
      a {
        border-radius: 0;
        text-transform: uppercase;
        font-family: $font-family__2;
        line-height: 30px;
        height: 30px;
        &:hover {
          color: $color__brand-purple !important;
        }
      }
    }
  }
}