.section-bg-image {
  position: absolute;
  left: 0;
  top: -60%;
  img {
    object-fit: cover;
  }

  &--right {
    left: auto;
    right: 0;
    top: auto;
    bottom: -30%;
  }

  & + div {
    z-index: 99;
    position: relative;
  }

  @media only screen and (max-width : 1440px) {
    top: -10%;
  }

  @media only screen and (max-width : 1280px) {
    top: 0;
    //position: absolute;
  }

  //@media only screen and (max-width : 800px) {
  //  position: relative;
  //}
}

.section-bg-image-2 {
  position: absolute;
  left: 0;
  width: 100%;
  .vc_single_image-wrapper {
    width: 100%;
  }
  img {
    width: 100%;
    object-fit: cover;
    object-position: top;
    max-height: 100%;
  }

  @media only screen and (max-width : 768px) {
    position: relative;
  }
}

.section-bg-image-dark-filter {
  &:after {
    content: '';
    background: rgba(0,0,0,.4);
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
  }
}

.section-bg-image-text-over {
  position: absolute;
  top: 40px;
  left: 40px;
  width: calc(100% - 80px);
  max-width: 500px;
  p {
    color: #fff;
  }
}