.colorful-tta {
  .vc_tta-panels {
    .vc_tta-panel {
      .vc_tta-panel-body {
        p {
          margin-bottom: 1.07em;
        }
      }
      .vc_tta-panel-title > a {
        display: table !important;
        text-align: center;
        cursor: pointer;
        font-family: $font-family__1 !important;
        font-size: $font-size__3 !important;
        letter-spacing: 1px !important;
        padding: 17px 30px 15px !important;
        line-height: $font-size__3 * 1.3 !important;
        background-color: transparent !important;
        height: auto !important;
        &:hover {
          text-decoration: underline;
          opacity: .9;
        }
        .vc_tta-controls-icon {
          display: none;
        }
        .vc_tta-title-text {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
    .vc_tta-panel:nth-of-type(1) {
      .vc_tta-panel-title > a {
        border: 1px solid $color__brand-purple !important;
        color: $color__brand-purple !important;
      }
    }
    .vc_tta-panel:nth-of-type(2) {
      .vc_tta-panel-title > a {
        border: 1px solid $color__brand-red !important;
        color: $color__brand-red !important;
      }
    }
  }
}