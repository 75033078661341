.table-1 {
  max-width: 650px;
  margin: 0 auto;
  table {
    background: transparent;
    //border: 1px solid #fff;
    border: 0;
    //border-spacing: 0;
    border-collapse: collapse;
    table-layout: fixed;

    //border-collapse: separate;
    border-spacing: 0 10px;
    //width: 100px;  /*Needs to be greater than the size of 'From:'*/
  }
  td, tr {
    //padding: 0;
    background: transparent !important;
    color: #fff;
    text-transform: uppercase;
    font-size: $font-size__2;
    letter-spacing: 2px;
    font-family: $font-family__2;
  }
}

.td-border-bottom {
  border-bottom: 1px solid #fff;
}

.tr-border-top {
  border-top: 1px solid #fff;
}

.tr-border-bottom {
  border-bottom: 1px solid #fff;
  margin-bottom: 10px;
}

.td-border-right {
  border-right: 1px solid #fff;
}