/*
Theme Name:     Up Communication
Theme URI: 		http://themes.dfd.name/ronneby/
Description:    ronneby - Highly Functional Multifaceted WP Theme
Author:         The Business Hood
Author URI:     http://thebusinesshood.com
Template:       ronneby
Version:        1.0.0
*/
.nowrap {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.hidden {
  display: none;
}

.hidden-2 {
  height: 0;
  visibility: hidden;
  overflow: hidden;
}

.visible-2 {
  height: auto;
  visibility: visible;
}

.visible {
  display: block;
}

.overflow-hidden {
  overflow: hidden;
}

.italic {
  font-style: italic;
}

.hidden-desktop {
  display: none;
}

@media only screen and (max-width: 736px) {
  .hidden-desktop {
    display: block;
  }
}

.hidden-mobile {
  display: block;
}

@media only screen and (max-width: 736px) {
  .hidden-mobile {
    display: none;
  }
}

.max-width-600 {
  max-width: 600px;
}

@font-face {
  font-family: "Gotham-Bold";
  src: url("fonts/Gotham-Bold/Gotham-Bold.eot?#iefix") format("embedded-opentype"), url("fonts/Gotham-Bold/Gotham-Bold.woff") format("woff"), url("fonts/Gotham-Bold/Gotham-Bold.woff") format("woff"), url("fonts/Gotham-Bold/Gotham-Bold.ttf") format("truetype"), url("fonts/Gotham-Bold/Gotham-Bold.svg#phantom") format("svg");
}

@font-face {
  font-family: "Gotham-Book";
  src: url("fonts/Gotham-Book/Gotham-Book.eot?#iefix") format("embedded-opentype"), url("fonts/Gotham-Book/Gotham-Book.woff") format("woff"), url("fonts/Gotham-Book/Gotham-Book.woff") format("woff"), url("fonts/Gotham-Book/Gotham-Book.ttf") format("truetype"), url("fonts/Gotham-Book/Gotham-Book.svg#phantom") format("svg");
}

@font-face {
  font-family: "GothamMedium";
  src: url("fonts/GothamMedium/GothamMedium.eot?#iefix") format("embedded-opentype"), url("fonts/GothamMedium/GothamMedium.woff") format("woff"), url("fonts/GothamMedium/GothamMedium.woff") format("woff"), url("fonts/GothamMedium/GothamMedium.ttf") format("truetype"), url("fonts/GothamMedium/GothamMedium.svg#phantom") format("svg");
}

@font-face {
  font-family: "GothamBlack";
  src: url("fonts/GothamBlack/GothamBlack.eot?#iefix") format("embedded-opentype"), url("fonts/GothamBlack/GothamBlack.woff") format("woff"), url("fonts/GothamBlack/GothamBlack.woff") format("woff"), url("fonts/GothamBlack/GothamBlack.ttf") format("truetype"), url("fonts/GothamBlack/GothamBlack.svg#phantom") format("svg");
}

.tp-caption.rev-btn {
  cursor: pointer;
  border: 1px solid #fff !important;
  font-family: "Gotham-Bold", sans-serif !important;
  font-size: 16px !important;
  letter-spacing: 1px !important;
  padding: 17px 30px 15px !important;
  line-height: 20.8px !important;
  background-color: transparent !important;
}

.tp-caption.rev-btn:hover {
  color: #fff !important;
  background: #404040 !important;
  border: 1px solid #404040 !important;
}

.tp-caption.rev-btn.btn-white-filled {
  color: #404040 !important;
  cursor: pointer;
  border: 1px solid #fff !important;
  font-family: "Gotham-Bold", sans-serif !important;
  font-size: 16px !important;
  letter-spacing: 1px !important;
  padding: 17px 30px 15px !important;
  line-height: 20.8px !important;
  background-color: #fff !important;
}

.tp-caption.rev-btn.btn-white-filled:hover {
  color: #fff !important;
  background: #404040 !important;
  border: 1px solid #404040 !important;
}

.btn-purple, .btn-purple-no-icon {
  display: inline-block;
  margin-bottom: 30px;
  cursor: pointer;
  border: 1px solid #562a7a !important;
  font-family: "Gotham-Bold", sans-serif !important;
  font-size: 24px !important;
  letter-spacing: 1px !important;
  padding: 17px 30px 15px !important;
  line-height: 31.2px !important;
  background-color: transparent !important;
  color: #562a7a !important;
}

.btn-purple:hover, .btn-purple-no-icon:hover {
  text-decoration: underline;
  opacity: .9;
}

.btn-purple:before, .btn-purple-no-icon:before {
  display: inline-block;
  content: '';
  width: 38px;
  height: 38px;
  background: url(img/btn-purple.png) center center no-repeat;
  background-size: contain;
  vertical-align: middle;
  margin-right: 15px;
  margin-top: -5px;
}

.btn-red, .btn-red-no-icon {
  display: inline-block;
  margin-bottom: 30px;
  cursor: pointer;
  border: 1px solid #ed2227 !important;
  font-family: "Gotham-Bold", sans-serif !important;
  font-size: 24px !important;
  letter-spacing: 1px !important;
  padding: 17px 30px 15px !important;
  line-height: 31.2px !important;
  background-color: transparent !important;
  color: #ed2227 !important;
}

.btn-red:hover, .btn-red-no-icon:hover {
  text-decoration: underline;
  opacity: .9;
}

.btn-red:before, .btn-red-no-icon:before {
  display: inline-block;
  content: '';
  width: 38px;
  height: 38px;
  background: url(img/btn-red.png) center center no-repeat;
  background-size: contain;
  vertical-align: middle;
  margin-right: 15px;
  margin-top: -5px;
}

.btn-orange {
  display: inline-block;
  margin-bottom: 30px;
  cursor: pointer;
  border: 1px solid #ec8b2e !important;
  font-family: "Gotham-Bold", sans-serif !important;
  font-size: 24px !important;
  letter-spacing: 1px !important;
  padding: 17px 30px 15px !important;
  line-height: 31.2px !important;
  background-color: transparent !important;
  color: #ec8b2e !important;
}

.btn-orange:hover {
  text-decoration: underline;
  opacity: .9;
}

.btn-orange:before {
  display: inline-block;
  content: '';
  width: 30px;
  height: 30px;
  background: url(img/btn-orange.png) center center no-repeat;
  background-size: contain;
  vertical-align: middle;
  margin-right: 15px;
  margin-top: -5px;
}

.dfd_contact_form .wpcf7-submit {
  margin-top: 50px !important;
  display: inline-block;
  margin-bottom: 30px;
  cursor: pointer;
  border: 1px solid #00a3db !important;
  font-family: "Gotham-Bold", sans-serif !important;
  font-size: 16px !important;
  padding: 17px 30px 15px !important;
  line-height: 20.8px !important;
  background-color: transparent !important;
  color: #00a3db !important;
}

.dfd_contact_form .wpcf7-submit:hover {
  color: #fff !important;
  background: #00a3db !important;
}

body, p, .wpb_wrapper p > a:not(.dk_toggle) {
  color: #404040;
  font-family: "Gotham-Book", sans-serif;
  font-size: 16px;
}

h3 {
  font-size: 24px;
}

.five-sixths,
.four-sixths,
.one-fourth,
.one-half,
.one-sixth,
.one-third,
.three-fourths,
.three-sixths,
.two-fourths,
.two-sixths,
.two-thirds {
  float: left;
  margin-left: 2.564102564102564%;
}

.one-half,
.three-sixths,
.two-fourths {
  width: 48.717948717948715%;
}

.one-third,
.two-sixths {
  width: 31.623931623931625%;
}

.four-sixths,
.two-thirds {
  width: 65.81196581196582%;
}

.one-fourth {
  width: 23.076923076923077%;
}

.three-fourths {
  width: 74.35897435897436%;
}

.one-sixth {
  width: 14.52991452991453%;
}

.five-sixths {
  width: 82.90598290598291%;
}

.first {
  clear: both;
  margin-left: 0;
}

.btn-purple-no-icon:before {
  display: none;
}

.btn-red-no-icon:before {
  display: none;
}

.btn-filled {
  color: #fff;
  padding: 15px 20px;
  text-transform: uppercase;
  font-family: "Gotham-Book", sans-serif;
  display: inline-block;
  margin: 20px 0;
}

.btn-filled--red {
  background-color: #ed2227;
}

.btn-filled--red:hover {
  background-color: #ef3a3e;
  color: #fff;
}

.btn-filled--purple {
  background-color: #562a7a;
}

.btn-filled--purple:hover {
  background-color: #63318d;
  color: #fff;
}

.section-overflow-hidden {
  overflow: hidden !important;
}

.section-bg-grey {
  background-color: #f6f6f6;
}

.section-bg-dark-grey {
  background-color: #404040;
}

.img-purple-shadow img {
  margin-right: 100px;
  max-width: 400px;
}

@media only screen and (max-width: 736px) {
  .img-purple-shadow img {
    margin-right: 0;
  }
}

.dfd-blog-loop .dfd-blog-wrap .post .cover .dfd-news-categories .byline.category a {
  background: #562a7a;
  font-family: "Gotham-Book", sans-serif;
}

.dfd-blog-title {
  font-family: "Gotham-Bold", sans-serif;
  letter-spacing: 0;
  font-size: 24px;
}

.dfd-blog-posts-module.dfd-blog-loop.standard .post .cover .entry-media .dfd-blog-heading-wrap {
  padding: 20px;
}

.dfd-blog .entry-thumb:after {
  content: '';
  background: rgba(0, 0, 0, 0.3);
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
}

.sort-panel {
  text-align: left;
}

.sort-panel .filter li {
  line-height: 20px;
}

.sort-panel .filter li.active a {
  border-color: #000;
}

.sort-panel .filter li a {
  border-radius: 0;
  text-transform: uppercase;
  font-family: "Gotham-Book", sans-serif;
  line-height: 30px;
  height: 30px;
}

.sort-panel .filter li a:hover {
  color: #562a7a !important;
}

.dfd-carousel-wrapper .dfd-carousel-module-wrapper .dfd-slick-dots li span:before {
  background: #404040 !important;
}

.column-max-width > .wpb_wrapper {
  max-width: 500px;
}

@media only screen and (max-width: 736px) {
  .column-max-width > .wpb_wrapper {
    max-width: none;
  }
}

.column-no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.column-padding-right {
  padding-right: 70px;
}

@media only screen and (max-width: 568px) {
  .column-padding-right {
    padding-right: 10px;
  }
}

.column-padding-left {
  padding-left: 70px;
}

@media only screen and (max-width: 736px) {
  .column-padding-left {
    padding-left: 10px;
  }
}

.dfd_contact_form input {
  color: #fff !important;
  font-family: "Gotham-Book", sans-serif !important;
}

.dfd_contact_form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff !important;
  font-family: "Gotham-Book", sans-serif !important;
}

.dfd_contact_form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff !important;
  font-family: "Gotham-Book", sans-serif !important;
}

.dfd_contact_form input:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff !important;
  font-family: "Gotham-Book", sans-serif !important;
}

.dfd_contact_form input:-moz-placeholder {
  /* Firefox 18- */
  color: #fff !important;
  font-family: "Gotham-Book", sans-serif !important;
}

.dfd_tabs_block .vc_tta-container {
  margin-bottom: 0 !important;
}

.dfd_tabs_block .tabs-1.dfd_tta_tabs.collapse .vc_tta-tabs-container {
  text-align: center !important;
}

.dfd_tabs_block .tabs-1.dfd_tta_tabs.collapse .vc_tta-tabs-container .vc_tta-tabs-list li a {
  border: none !important;
  font-family: "Gotham-Bold", sans-serif;
}

.dfd_tabs_block .tabs-1.dfd_tta_tabs.collapse .vc_tta-tabs-container .vc_tta-tabs-list li:nth-of-type(1) a {
  color: #562a7a !important;
}

.dfd_tabs_block .tabs-1.dfd_tta_tabs.collapse .vc_tta-tabs-container .vc_tta-tabs-list li:nth-of-type(1).vc_active a {
  border: 1px solid #562a7a !important;
  color: #562a7a;
  background-color: transparent !important;
}

.dfd_tabs_block .tabs-1.dfd_tta_tabs.collapse .vc_tta-tabs-container .vc_tta-tabs-list li:nth-of-type(2) a {
  color: #ed2227 !important;
}

.dfd_tabs_block .tabs-1.dfd_tta_tabs.collapse .vc_tta-tabs-container .vc_tta-tabs-list li:nth-of-type(2).vc_active a {
  border: 1px solid #ed2227 !important;
  color: #ed2227;
  background-color: transparent !important;
}

.dfd_tabs_block .tabs-1.dfd_tta_tabs.collapse .vc_tta-tabs-container .vc_tta-tabs-list li:nth-of-type(3) a {
  color: #ec8b2e !important;
}

.dfd_tabs_block .tabs-1.dfd_tta_tabs.collapse .vc_tta-tabs-container .vc_tta-tabs-list li:nth-of-type(3).vc_active a {
  border: 1px solid #ec8b2e !important;
  color: #ec8b2e;
  background-color: transparent !important;
}

.dfd_tabs_block .tabs-1.dfd_tta_tabs.collapse .vc_tta-tabs-container .vc_tta-tabs-list li:nth-of-type(4) a {
  color: #0965a6 !important;
}

.dfd_tabs_block .tabs-1.dfd_tta_tabs.collapse .vc_tta-tabs-container .vc_tta-tabs-list li:nth-of-type(4).vc_active a {
  border: 1px solid #0965a6 !important;
  color: #0965a6;
  background-color: transparent !important;
}

.dfd_tabs_block .tabs-2.dfd_tta_tabs.collapse .vc_tta-tabs-container {
  text-align: center !important;
}

.dfd_tabs_block .tabs-2.dfd_tta_tabs.collapse .vc_tta-tabs-container .vc_tta-tabs-list li a {
  border: none !important;
  font-family: "Gotham-Bold", sans-serif;
  color: #fff;
}

.dfd_tabs_block .tabs-2.dfd_tta_tabs.collapse .vc_tta-tabs-container .vc_tta-tabs-list li.vc_active a {
  background-color: transparent !important;
  border: 1px solid #fff !important;
}

.font-medium {
  font-family: "GothamMedium", sans-serif;
}

.font-book {
  font-family: "Gotham-Book", sans-serif;
}

.font-small {
  font-size: 14px;
}

.font-size-3 {
  font-size: 24px;
}

.font-strong-replace p strong {
  font-family: "Gotham-Bold", sans-serif;
}

#footer .dfd-new-soc-icon a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.logo-for-panel a img {
  margin: 20px;
}

#header-container #header .header-wrap > .row:last-child .header-main-panel .header-col-fluid .fixed-header-logo:before {
  background: transparent;
}

#menu-corporate_menu li a {
  text-transform: uppercase;
  font-weight: normal;
  font-family: "GothamMedium", sans-serif;
  transition: color .3s ease-out;
  color: #404040 !important;
}

#menu-corporate_menu li:after {
  display: none !important;
}

#menu-corporate_menu li:hover a {
  color: rgba(64, 64, 64, 0.7) !important;
}

.sticky-header-enabled.small #menu-corporate_menu li a {
  color: #fff !important;
}

.sticky-header-enabled.small #menu-corporate_menu li:hover a {
  color: rgba(255, 255, 255, 0.7) !important;
}

.home #menu-corporate_menu li a, .page-id-18296 #menu-corporate_menu li a, .page-id-18116 #menu-corporate_menu li a {
  color: #fff !important;
}

.home #menu-corporate_menu li:hover a, .page-id-18296 #menu-corporate_menu li:hover a, .page-id-18116 #menu-corporate_menu li:hover a {
  color: rgba(64, 64, 64, 0.7) !important;
}

.page-heading h1 {
  font-family: "Gotham-Bold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 34px;
  color: #404040;
}

.row-image-stretch .wpb_row {
  display: flex !important;
  align-items: stretch;
}

@media only screen and (max-width: 768px) {
  .row-image-stretch .wpb_row {
    display: block !important;
  }
}

.column-image-stretch > .wpb_wrapper {
  height: 100% !important;
}

.column-image-stretch > .wpb_wrapper > .wpb_single_image {
  height: 100% !important;
}

.column-image-stretch > .wpb_wrapper > .wpb_single_image > .wpb_wrapper {
  height: 100% !important;
}

.column-image-stretch > .wpb_wrapper > .wpb_single_image > .wpb_wrapper > .vc_single_image-wrapper {
  height: 100% !important;
}

.column-image-stretch > .wpb_wrapper > .wpb_single_image > .wpb_wrapper > .vc_single_image-wrapper img {
  height: 100% !important;
  object-position: center;
}

.section-bg-image {
  position: absolute;
  left: 0;
  top: -60%;
}

.section-bg-image img {
  object-fit: cover;
}

.section-bg-image--right {
  left: auto;
  right: 0;
  top: auto;
  bottom: -30%;
}

.section-bg-image + div {
  z-index: 99;
  position: relative;
}

@media only screen and (max-width: 1440px) {
  .section-bg-image {
    top: -10%;
  }
}

@media only screen and (max-width: 1280px) {
  .section-bg-image {
    top: 0;
  }
}

.section-bg-image-2 {
  position: absolute;
  left: 0;
  width: 100%;
}

.section-bg-image-2 .vc_single_image-wrapper {
  width: 100%;
}

.section-bg-image-2 img {
  width: 100%;
  object-fit: cover;
  object-position: top;
  max-height: 100%;
}

@media only screen and (max-width: 768px) {
  .section-bg-image-2 {
    position: relative;
  }
}

.section-bg-image-dark-filter:after {
  content: '';
  background: rgba(0, 0, 0, 0.4);
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
}

.section-bg-image-text-over {
  position: absolute;
  top: 40px;
  left: 40px;
  width: calc(100% - 80px);
  max-width: 500px;
}

.section-bg-image-text-over p {
  color: #fff;
}

.section-title {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-family: "GothamBlack", sans-serif;
}

.section-title:before {
  content: '';
  background: url(img/logo-small.png) center center no-repeat;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 15px;
  background-size: cover;
}

@media only screen and (max-width: 568px) {
  .section-title {
    font-size: 24px;
    text-align: center !important;
  }
}

.section-title--white {
  color: #fff;
}

.section-title--white:before {
  display: none;
}

.separator-under-text {
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.separator-under-text p {
  letter-spacing: 3px;
}

.separator-under-text:after {
  content: '';
  display: block;
  height: 1px;
  background-color: #000;
  width: 100px;
  margin-top: 20px;
}

h1.tp-caption {
  font-style: normal !important;
  font-family: "Gotham-Bold", sans-serif !important;
  text-transform: uppercase;
  font-size: 56px !important;
  letter-spacing: 4px !important;
  font-weight: normal !important;
  line-height: 72.8px !important;
}

@media only screen and (max-width: 1024px) {
  h1.tp-caption {
    font-size: 34px !important;
    line-height: 44.2px !important;
  }
}

@media only screen and (max-width: 568px) {
  h1.tp-caption {
    font-size: 24px !important;
    line-height: 31.2px !important;
  }
}

.tp-revslider-mainul li:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
}

.dfd-subscribe-module-form table {
  border: 1px solid #404040 !important;
}

.dfd-subscribe-module-form table input, .dfd-subscribe-module-form table button {
  font-family: "Gotham-Book", sans-serif !important;
}

.dfd-subscribe-module-form table .cell-submit {
  left: 4px !important;
  right: auto !important;
}

.dfd-subscribe-module-form table button {
  font-size: 16px !important;
  background-color: #d92229 !important;
}

.dfd-subscribe-module-form table input {
  text-align: right;
}

.dfd-subscribe-module-form table input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: "Gotham-Book", sans-serif !important;
  font-size: 16px !important;
  text-align: right;
}

.dfd-subscribe-module-form table input::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Gotham-Book", sans-serif !important;
  font-size: 16px !important;
  text-align: right;
}

.dfd-subscribe-module-form table input:-ms-input-placeholder {
  /* IE 10+ */
  font-family: "Gotham-Book", sans-serif !important;
  font-size: 16px !important;
  text-align: right;
}

.dfd-subscribe-module-form table input:-moz-placeholder {
  /* Firefox 18- */
  font-family: "Gotham-Book", sans-serif !important;
  font-size: 16px !important;
  text-align: right;
}

.table-1 {
  max-width: 650px;
  margin: 0 auto;
}

.table-1 table {
  background: transparent;
  border: 0;
  border-collapse: collapse;
  table-layout: fixed;
  border-spacing: 0 10px;
}

.table-1 td, .table-1 tr {
  background: transparent !important;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 2px;
  font-family: "Gotham-Book", sans-serif;
}

.td-border-bottom {
  border-bottom: 1px solid #fff;
}

.tr-border-top {
  border-top: 1px solid #fff;
}

.tr-border-bottom {
  border-bottom: 1px solid #fff;
  margin-bottom: 10px;
}

.td-border-right {
  border-right: 1px solid #fff;
}

.testimonial-content {
  font-style: normal;
  font-family: "Gotham-Book", sans-serif;
  font-size: 18px;
  line-height: 25.2px;
}

.testimonial-title {
  font-family: "Gotham-Bold", sans-serif !important;
  font-size: 16px;
  letter-spacing: 0px !important;
  font-size: 18px !important;
}

.testimonial-subtitle {
  font-style: normal;
  color: #404040 !important;
  font-size: 18px !important;
}

.slick-list {
  margin-bottom: 100px;
}

.dfd-slick-dots {
  bottom: -150px !important;
}

.text-small p {
  text-transform: uppercase;
}

.text-big p {
  text-transform: uppercase;
  font-family: "Gotham-Bold", sans-serif;
  font-size: 24px;
  letter-spacing: 3px;
  line-height: 33.6px;
}

@media only screen and (max-width: 568px) {
  .text-big p {
    font-size: 16px;
    line-height: 20.8px;
  }
}

.text-right {
  text-align: right;
}

.text-right p, .text-right a {
  text-align: right;
}

@media only screen and (max-width: 568px) {
  .text-right {
    text-align: left;
  }
  .text-right p, .text-right a {
    text-align: left;
  }
}

.colorful-tta .vc_tta-panels .vc_tta-panel .vc_tta-panel-body p {
  margin-bottom: 1.07em;
}

.colorful-tta .vc_tta-panels .vc_tta-panel .vc_tta-panel-title > a {
  display: table !important;
  text-align: center;
  cursor: pointer;
  font-family: "Gotham-Bold", sans-serif !important;
  font-size: 24px !important;
  letter-spacing: 1px !important;
  padding: 17px 30px 15px !important;
  line-height: 31.2px !important;
  background-color: transparent !important;
  height: auto !important;
}

.colorful-tta .vc_tta-panels .vc_tta-panel .vc_tta-panel-title > a:hover {
  text-decoration: underline;
  opacity: .9;
}

.colorful-tta .vc_tta-panels .vc_tta-panel .vc_tta-panel-title > a .vc_tta-controls-icon {
  display: none;
}

.colorful-tta .vc_tta-panels .vc_tta-panel .vc_tta-panel-title > a .vc_tta-title-text {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.colorful-tta .vc_tta-panels .vc_tta-panel:nth-of-type(1) .vc_tta-panel-title > a {
  border: 1px solid #562a7a !important;
  color: #562a7a !important;
}

.colorful-tta .vc_tta-panels .vc_tta-panel:nth-of-type(2) .vc_tta-panel-title > a {
  border: 1px solid #ed2227 !important;
  color: #ed2227 !important;
}
