.row-image-stretch {
  .wpb_row {
    display: flex !important;
    align-items: stretch;

    @media only screen and (max-width : 768px) {
      display: block !important;
    }

    @media only screen and (max-width : 568px) {
    }
  }
}

.column-image-stretch {
  > .wpb_wrapper {
    height: 100% !important;
    > .wpb_single_image {
      height: 100% !important;
      > .wpb_wrapper {
        height: 100% !important;
        > .vc_single_image-wrapper {
          height: 100% !important;
          img {
            height: 100% !important;
            object-position: center;
          }
        }
      }
    }
  }
}