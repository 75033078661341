.column-max-width {
  > .wpb_wrapper {
    max-width: 500px;
    @media only screen and (max-width : 736px) {
      max-width: none;
    }
  }
}

.column-no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.column-padding-right {
  padding-right: 70px;

  @media only screen and (max-width : 568px) {
    padding-right: 10px;
  }
}

.column-padding-left {
  padding-left: 70px;

  @media only screen and (max-width : 736px) {
    padding-left: 10px;
  }
}