.nowrap {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.hidden {
  display: none;
}

.hidden-2 {
  height: 0;
  visibility: hidden;
  overflow: hidden;
}

.visible-2 {
  height: auto;
  visibility: visible;
}

.visible {
  display: block;
}

.overflow-hidden {
  overflow: hidden;
}

.italic {
  font-style: italic;
}

.hidden-desktop {
  display: none;
  @media only screen and (max-width : $responsive-breakpoint) {
    display: block;
  }
}

.hidden-mobile {
  display: block;
  @media only screen and (max-width : $responsive-breakpoint) {
    display: none;
  }
}

.max-width-600 {
  max-width: 600px;
  //margin: 0 auto;
  //float: none !important;
}
