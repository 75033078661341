// Rem output with px fallback
@mixin font-size($sizeValue: 1) {
	font-size: ($sizeValue * 16) * 1px;
	font-size: $sizeValue * 1rem;
}

// Hover
@mixin hover-focus {
	&:focus,
	&:hover {
		@content
	}
}

// Placeholder
@mixin placeholder {
	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		@content
	}

	::-moz-placeholder { /* Firefox 19+ */
		@content
	}

	:-ms-input-placeholder { /* IE 10+ */
		@content
	}

	:-moz-placeholder { /* Firefox 18- */
		@content
	}
}

@mixin placeholder-deep {
	&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		@content
	}

	&::-moz-placeholder { /* Firefox 19+ */
		@content
	}

	&:-ms-input-placeholder { /* IE 10+ */
		@content
	}

	&:-moz-placeholder { /* Firefox 18- */
		@content
	}
}


// Center block
@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

// Clearfix
@mixin clearfix() {
	content: "";
	display: table;
	table-layout: fixed;
}

// Clear after (not all clearfix need this also)
@mixin clearfix-after() {
	clear: both;
}

// Font face
@mixin font-face($name: null) {
	font-family: $name;
	src: url('fonts/#{$name}/#{$name}.eot?#iefix') format('embedded-opentype'),  url('fonts/#{$name}/#{$name}.woff') format('woff'), url('fonts/#{$name}/#{$name}.woff') format('woff'),  url('fonts/#{$name}/#{$name}.ttf') format('truetype'),  url('fonts/#{$name}/#{$name}.svg#phantom') format('svg');
	//font-weight: normal;
	//font-style: normal;
}

// Lists
@mixin list-unstyled {
	margin-left: 0;
	padding-left: 0;
	list-style: none;
}