.separator-under-text {
  padding-bottom: 40px;
  margin-bottom: 40px;
  p {
    letter-spacing: 3px;
  }
  &:after {
    content: '';
    display: block;
    height: 1px;
    background-color: #000;
    width: 100px;
    margin-top: 20px;
  }
}