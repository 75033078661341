.text-small {
  p {
    text-transform: uppercase;
  }
}

.text-big {
  p {
    text-transform: uppercase;
    font-family: $font-family__1;
    font-size: $font-size__3;
    letter-spacing: 3px;
    line-height: $font-size__3 * 1.4;
    @media only screen and (max-width : 568px) {
      font-size: $font-size__2;
      line-height: $font-size__2 * 1.3;
    }
  }
}

.text-right {
  text-align: right;
  p, a {
    text-align: right;
  }

  @media only screen and (max-width : 568px) {
    text-align: left;
    p, a {
      text-align: left;
    }
  }
}