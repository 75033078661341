.testimonial-content {
  font-style: normal;
  font-family: $font-family__2;
  font-size: $font-size__4;
  line-height: $font-size__4 * 1.4;
}

.testimonial-title {
  font-family: $font-family__1 !important;
  font-size: $font-size__2;
  letter-spacing: 0px !important;
  font-size: $font-size__4 !important;
}

.testimonial-subtitle {
  font-style: normal;
  color: $color__brand-black !important;
  font-size: $font-size__4 !important;
}

.slick-list {
  margin-bottom: 100px;
}

.dfd-slick-dots {
  bottom: -150px !important;
}