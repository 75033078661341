.font-medium {
  font-family: $font-family__3;
}

.font-book {
  font-family: $font-family__2;
}

.font-small {
  font-size: $font-size__5;
}

.font-size-3 {
  font-size: $font-size__3;
}

.font-strong-replace {
  p strong {
    font-family: $font-family__1;
  }
}