.section-overflow-hidden {
  overflow: hidden !important;
}

.section-bg-grey {
  background-color: $color__brand-grey;
}

.section-bg-dark-grey {
  background-color: $color__brand-black;
}

.img-purple-shadow {
  img {
    margin-right: 100px;
    max-width: 400px;
    @media only screen and (max-width : 736px) {
      margin-right: 0;
    }
  }
}