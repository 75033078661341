.section-title {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-family: $font-family__4;
  &:before {
    content: '';
    background: url(img/logo-small.png) center center no-repeat;
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 15px;
    background-size: cover;
  }

  @media only screen and (max-width : 568px) {
    font-size: $font-size__3;
    text-align: center !important;
  }
}

.section-title--white {
  color: #fff;
  &:before {
    display: none;
  }
}