.dfd-subscribe-module-form {
  table {
    border: 1px solid $color__brand-black !important;

    input, button {
      font-family: $font-family__2 !important;
    }

    .cell-submit {
      left: 4px !important;
      right: auto !important;
    }

    button {
      font-size: $font-size__2 !important;
      background-color: $color__brand-darken-red !important;

    }

    input {
      text-align: right;
      @include placeholder-deep {
        font-family: $font-family__2 !important;
        font-size: $font-size__2 !important;
        text-align: right;
      }
    }
  }
}