%btn-white {
  cursor: pointer;
  border: 1px solid #fff !important;
  font-family: $font-family__1 !important;
  font-size: $font-size__2 !important;
  letter-spacing: 1px !important;
  padding: 17px 30px 15px !important;
  line-height: $font-size__2 * 1.3 !important;
  background-color: transparent !important;
  &:hover {
    color: #fff !important;
    background: $color__brand-black !important;
    border: 1px solid $color__brand-black !important;
  }
}
//
%btn-white-filled {
  color: $color__brand-black !important;
  cursor: pointer;
  border: 1px solid #fff !important;
  font-family: $font-family__1 !important;
  font-size: $font-size__2 !important;
  letter-spacing: 1px !important;
  padding: 17px 30px 15px !important;
  line-height: $font-size__2 * 1.3 !important;
  background-color: #fff !important;
  &:hover {
    color: #fff !important;
    background: $color__brand-black !important;
    border: 1px solid $color__brand-black !important;
  }
}

%btn-purple {
  display: inline-block;
  margin-bottom: 30px;
  cursor: pointer;
  border: 1px solid $color__brand-purple !important;
  font-family: $font-family__1 !important;
  font-size: $font-size__3 !important;
  letter-spacing: 1px !important;
  padding: 17px 30px 15px !important;
  line-height: $font-size__3 * 1.3 !important;
  background-color: transparent !important;
  color: $color__brand-purple !important;
  &:hover {
    text-decoration: underline;
    opacity: .9;
  }
  &:before {
    display: inline-block;
    content: '';
    width: 38px;
    height: 38px;
    background: url(img/btn-purple.png) center center no-repeat;
    background-size: contain;
    vertical-align: middle;
    margin-right: 15px;
    margin-top: -5px;
  }
}

%btn-red {
  display: inline-block;
  margin-bottom: 30px;
  cursor: pointer;
  border: 1px solid $color__brand-red !important;
  font-family: $font-family__1 !important;
  font-size: $font-size__3 !important;
  letter-spacing: 1px !important;
  padding: 17px 30px 15px !important;
  line-height: $font-size__3 * 1.3 !important;
  background-color: transparent !important;
  color: $color__brand-red !important;
  &:hover {
    text-decoration: underline;
    opacity: .9;
  }
  &:before {
    display: inline-block;
    content: '';
    width: 38px;
    height: 38px;
    background: url(img/btn-red.png) center center no-repeat;
    background-size: contain;
    vertical-align: middle;
    margin-right: 15px;
    margin-top: -5px;
  }
}

%btn-orange {
  display: inline-block;
  margin-bottom: 30px;
  cursor: pointer;
  border: 1px solid $color__brand-orange !important;
  font-family: $font-family__1 !important;
  font-size: $font-size__3 !important;
  letter-spacing: 1px !important;
  padding: 17px 30px 15px !important;
  line-height: $font-size__3 * 1.3 !important;
  background-color: transparent !important;
  color: $color__brand-orange !important;
  &:hover {
    text-decoration: underline;
    opacity: .9;
  }
  &:before {
    display: inline-block;
    content: '';
    width: 30px;
    height: 30px;
    background: url(img/btn-orange.png) center center no-repeat;
    background-size: contain;
    vertical-align: middle;
    margin-right: 15px;
    margin-top: -5px;
  }
}

%btn-dark-green {
  margin-top: 50px !important;
  display: inline-block;
  margin-bottom: 30px;
  cursor: pointer;
  border: 1px solid $color__brand-dark-green !important;
  font-family: $font-family__1 !important;
  font-size: $font-size__2 !important;
  //letter-spacing: 1px !important;
  padding: 17px 30px 15px !important;
  line-height: $font-size__2 * 1.3 !important;
  background-color: transparent !important;
  color: $color__brand-dark-green !important;
  &:hover {
    color: #fff !important;
    background: $color__brand-dark-green !important;
  }
}