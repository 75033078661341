.dfd_contact_form {
  input {
    color: #fff !important;
    font-family: $font-family__2 !important;
    @include placeholder-deep {
      color: #fff !important;
      font-family: $font-family__2 !important;
    }
  }
  .wpcf7-submit {
    @extend %btn-dark-green;
  }
}